<template>
  <div class="pay">
    <div class="pay-box" v-if="userStatus()">
      <div class="pay-box-info">
        当前选中充值会员：
        <el-tag closable type="warning" @close="showUser">
          {{ getUserInfo() }}
        </el-tag>
      </div>

      <div class="pay-box-cont">
        <div class="pay-box-cont-list">
          <div class="pay-box-cont-list-item">
            <span>充值金额(元)：</span>
            <div class="pay-box-cont-list-item-r">
              <el-input-number
                v-model="datas.order_amount"
                :precision="2"
                :step="0.1"
                :min="1"
                :max="99999999"
                label="描述文字"
              ></el-input-number>
            </div>
          </div>
          <div class="pay-box-cont-list-item">
            <span>实收金额(元)：</span>
            <div class="pay-box-cont-list-item-r">
              <el-input-number
                v-model="datas.sale_amount"
                :precision="2"
                :step="0.1"
                :min="1"
                :max="99999999"
                label="描述文字"
              ></el-input-number>
            </div>
          </div>
        </div>
        <div class="pay-box-cont-list">
          <div class="pay-box-cont-list-item">
            <span>销售员工：</span>
            <div class="pay-box-cont-list-item-r">
              <el-select
                v-model="datas.staff_ids"
                filterable
                multiple
                placeholder="请选择员工"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="pay-box-cont-list-item">
            <span>支付方式：</span>
            <div class="pay-box-cont-list-item-r">
              <el-select v-model="datas.order_pay" placeholder="请选择支付方式">
                <el-option
                  v-for="item in options1"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="pay-box-cont-list">
          <div class="pay-box-cont-list-item" style="width:100%">
            <span>充值备注：</span>
            <div class="pay-box-cont-list-item-r" style="width:83%">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="datas.remark"
                maxlength="150"
                show-word-limit
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>

      <div class="pay-box-btn">
        <span>￥{{ datas.sale_amount.toFixed(2) || "0.00" }}</span>
        <el-button type="danger" @click="pays">确定充值</el-button>
      </div>
    </div>
    <div class="pay-box-no-user" v-else>
      <el-tooltip
        class="item"
        effect="dark"
        content="点击我选择会员"
        placement="top-start"
      >
        <el-button type="primary" @click="showUser">请先选择会员</el-button>
      </el-tooltip>
    </div>
    <!-- 没有会员id弹出搜索会员窗口 -->
    <div v-if="shows && !userStatus()">
      <selectUser />
    </div>
    <!-- 没有会员id弹出搜索会员窗口 -->
  </div>
</template>

<script>
/* 注意：优先获取父级传递过来的会员数据 */
import selectUser from "../home/selectVip.vue";
export default {
  name: "pay",
  props: ["userId", "work"],
  components: { selectUser },
  data() {
    return {
      dialogVisible: false,
      datas: {
        member_id: "",
        order_amount: 1,
        sale_amount: 1,
        order_pay: "",
        staff_ids: [],
        remark: "",
      },
      options: [],
      options1: [
        {
          id: 1,
          name: "现金",
        },
        {
          id: 2,
          name: "微信",
        },
        {
          id: 3,
          name: "支付宝",
        },
        {
          id: 4,
          name: "银行卡",
        },
      ],
    };
  },
  methods: {
    showUser() {
      this.$store.commit("selectVipShows", true);
      this.$store.commit("setUser", {});
    },
    userStatus() {
      if (this.userId || this.userInfo.name) {
        return true;
      } else {
        return false;
      }
    },
    getUserInfo() {
      return (this.userId ? this.userId.name : null) || this.userInfo.name;
    },
    pays() {
      if (!this.userStatus) {
        this.$message.error("请选择会员");
        return false;
      }
      let d = JSON.parse(JSON.stringify(this.datas));

      if (!d.order_amount || !d.sale_amount) {
        this.$message.error("请输入充值金额");
        return false;
      }
      if (d.order_amount < d.sale_amount) {
        this.$message.error("请注意：实收金额不能大于充值金额");
        return false;
      }
      if (!d.staff_ids.length) {
        this.$message.error("请选择销售员工");
        return false;
      }
      if (!d.order_pay) {
        this.$message.error("请选择支付方式");
        return false;
      }
      d.member_id = (this.userId ? this.userId.id : null) || this.userInfo.id;
      d.order_pay = JSON.stringify([
        { pay_type: d.order_pay, pay_amount: d.sale_amount },
      ]);
      d.staff_ids = JSON.stringify(d.staff_ids);
      console.log(d);

      this.$axios({
        href: "/api/app/meiye/payment/recharge",
        data: d,
      }).then((res) => {
        console.log(res);
        if (res.data.successful) {
          (this.datas = {
            member_id: "",
            order_amount: 1,
            sale_amount: 1,
            order_pay: "",
            staff_ids: [],
            remark: "",
          }),
            this.$message({
              message: "充值成功~",
              type: "success",
            });
          this.$store.commit("selectVipShows", false);
          this.$store.commit("setUser", {});
          this.$store.commit("setPay", false);
        }
      });
    },
    // change(val){
    //     this.datas.sale_amounts = val;
    // }
  },
  created() {
    if (!this.userId && !this.userInfo.name) {
      this.$store.commit("selectVipShows", true);
      console.log(this.userId);
    }
    if (this.work) {
      this.options = this.work;
    } else {
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
      }).then((res) => {
        this.options = res.data;
      });
    }
  },
  computed: {
    shows: {
      get() {
        return this.$store.state.order.selectVip;
      },
      set() {
        return this.$store.state.order.selectVip;
      },
    },
    userInfo() {
      return this.$store.state.order.user;
    },
  },
  //   watch: {
  //     shows(val) {
  //       console.log;
  //       if (!val) {
  //         this.$store.commit("selectVipShows", true);
  //       } else {
  //         this.$store.commit("selectVipShows", false);
  //       }
  //     },
  //   },
};
</script>

<style lang="scss" scoped>
.pay {
  width: 100%;
  height: 100%;
  &-box {
    width: 100%;
    max-height: 500px;
    &-info {
      width: 100%;
      height: 50px;
      padding: 10px;
      margin-bottom: 10px;
      background: rgb(255, 240, 240);
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      //   background: red;
    }
    &-cont {
      width: 100%;
      height: 100%;

      &-list {
        width: 100%;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // background: red;
        margin-bottom: 10px;
        &-item {
          width: 50%;
          min-height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          //   background: red;
          margin: 0 0.5%;
          span {
            display: inline-block;
            width: 100px;
            height: 100%;
            line-height: 60px;
          }
          &-r {
            width: 170px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    &-btn {
      width: 100%;
      height: 180px;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      span {
        font-size: 24px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: Bold;
        text-align: left;
        color: #f57665;
        margin-right: 20px;
      }
    }
  }
  &-box-no-user {
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.548);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
